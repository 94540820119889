import React, {useState} from 'react'
import {ULPack} from "./uLPack"
import {Link} from "react-scroll"
//${process.env.PUBLIC_URL + '/folder/image.svg'}

function Ru() {

    function pageClick(e){if (e.target.className !== 'DropDown__active-name') {if (document.getElementsByClassName('DropDown__list').length === 1) {document.getElementsByClassName('DropDown__active-name')[1].click()}}}
    function intRedirect (lang) {window.location.href = document.location.origin + '/' + lang}
    let internalPatch = document.location.pathname.split('/')[1]
    let pageLangContent = ULPack.language.ru
    let documentTitle = 'Софт по подписке'
    if ((['en', 'eng']).indexOf(internalPatch) > -1) {pageLangContent = ULPack.language.en;documentTitle = 'Software by subscription'} else if ((['es', 'esp']).indexOf(internalPatch) > -1) {pageLangContent = ULPack.language.es;documentTitle = 'Software por suscripción'}
    document.title = documentTitle
    const pageAllContent = ULPack.all
    const [menu, setMenu] = useState(false)
    const iconList = (() => {let arr = [], obj = pageLangContent.iconList;for (let key in obj) {arr.push(<div className="icons__item icons__item_max-width" key={key}><img className="icons__item-icon" src={`/img/${key}.svg`} alt={key}/><div className="icons__item-text icons__item-text_center">{obj[key]}</div></div>);}return arr;})();
    const iconList2 = (() => {let arr = [], obj = pageLangContent.iconListTwo;for (let key in obj) {arr.push(<div className="icons__item icons__item_max-width2" key={key}><img className="icons__item-icon" src={`/img/${key}.svg`} alt={key}/><div><div className="icons__item-text2">{obj[key][0]}</div><div className="icons__item-text">{obj[key][1]}</div></div></div>);arr.push(<div className="icons__item" key={key + '2'}><img className="icons__item-icon arrow" src={`/img/arrow.svg`} alt="arrow.svg"/></div>);}arr.pop();return arr;})()
    const partners = (() => {let cls = '', arr = [], partners = pageLangContent.partners;partners.forEach(function(item, ind) {cls = 'gridPartners__item ' + (item[1] ? item[1] : 'brandsBg__white');arr.push(<div className={cls} key={item[0]}><img src={`/img/logos/${item[0]}`} alt={item[0]}/></div>);});return arr;})();

    // DropDown
    const DropDown = (props) => {
        const [show, setShow] = useState(false)
        let items = (() => {let arr = [];for (let key in props.items) {if (key !== pageLangContent.dropDownActive){arr.push(<div key={key} className="DropDown__list-item" onClick={e => intRedirect(key)}>{props.items[key]}</div>);}}return arr;})();

        return <div className="DropDown">
            <div className="DropDown__active" onClick={() => setShow(!show)}><div className="DropDown__active-name">{props.items[pageLangContent.dropDownActive]}</div><img className="DropDown__active-arrow" src="/img/arrow_drop.svg" alt="arrow"/></div>
            {show && <div className="DropDown__list">{items}</div>}
        </div>
    }
    // DropDown

    return (
        <div id="page" onClick={e => pageClick(e)}>
            <div className="header">
                <a href={document.location.origin}><img className="header__img" src="/img/img1.svg" alt="logo Software by subscription"/></a>

                <div className="header__menu">{pageAllContent.topMenuLinks.map((item, i) => {
                    return <Link activeClass="active" to={''+item} spy={true} smooth={true} offset={-100} duration={500} key={i} href={'#'+item}>{pageLangContent.topMenuLinksTitle[i]}</Link>
                })}</div>
                {!menu && <div className="header__languageMobile"><DropDown action={key => intRedirect(key)} items={pageAllContent.dropDown}/></div>}
                {menu && <div className="header__menuMobile">{pageAllContent.topMenuLinks.map((item, i) => {
                    return <Link activeClass="active" to={''+item} spy={true} smooth={true} offset={-100} duration={500} key={i} href={'#'+item}>{pageLangContent.topMenuLinksTitle[i]}</Link>
                })}</div>}

                <div className="header__choice">
                    {!menu && <div className="header__choice-language"><DropDown action={key => intRedirect(key)} items={pageAllContent.dropDown}/></div>}
                    <div className="header__choice-icon">{menu ? <img className="iconCross" src="/img/cross.svg" alt="cross" onClick={() => setMenu(false)}/> : <img className="iconMenu" src="/img/menu.svg" alt="menu" onClick={() => setMenu(true)}/>}</div>
                </div>
            </div>

            <div className="content">

                <div className="content__item content__item_backgroundBlue">
                    <div className="banner content__item-subItem" >
                        <div className="banner__left" >
                            <div className="title">{pageLangContent.content.title}</div>
                            <div className="banner__left-text">{pageLangContent.content.text}</div>
                            <Link activeClass="active" to={'solution'} spy={true} smooth={true} offset={-100} duration={500} href="#solution" className="banner__left-button">{pageLangContent.content.button}</Link>
                        </div>
                        <div className="banner__right" style={{backgroundImage: `url('/img/img2.svg')` }} />
                    </div>
                </div>

                <div id="solution" className="content__item content__item_backgroundGray"><div className="content__item-subItem" ><div className="title">{pageLangContent.solution.title}</div><div className="text">{pageLangContent.solution.text}</div><div className="icons">{iconList}</div></div></div>

                <div id="whatOffer" className="content__item">
                    <div className="content__item-subItem" >
                        <div className="title">{pageLangContent.whatOffer.title}</div>
                        <div className="text">{pageLangContent.whatOffer.text[0]}</div>
                        <div className="icons">{iconList2}</div>
                        <div className="title2">{pageLangContent.whatOffer.text[1]}</div>
                        <div className="text">{pageLangContent.whatOffer.text[2]}</div>
                        <div className="text">{pageLangContent.whatOffer.text[3]}</div>
                        <div className="title2">{pageLangContent.whatOffer.text[4]}</div>
                        <div className="gridText">{pageLangContent.whatOffer.auditory.map((item, i) => {return <div key={i} className="gridText__item"><div className="point"/><div className="text">{item}</div></div>})}</div>
                    </div>
                </div>

                <div id="contactUs"><div className="content__item-subItem" ><div className="text"><div className="apiDoc__text-prepare">{pageLangContent.contactUs.text}</div><br /><a href={'mailto:' + pageLangContent.contactUs.mailTo} className="apiDoc__text-prepare">Email: {pageLangContent.contactUs.mailTo.split('?')[0]}</a></div></div></div>

                <div id="benefit" className="content__item content__item_backgroundGray">
                    <div className="content__item-subItem" style={{backgroundImage: `url('/img/img3.svg')` }}>
                        <div className="title">{pageLangContent.benefit.title}</div>
                        <div className="benefitBanner">
                            <div className="benefitBanner__item"><img src="/img/rostelecom.svg" alt="rostelecom.svg"/><div className="benefitBanner__item-text">{pageLangContent.benefit.rt.title}</div>{pageLangContent.benefit.rt.point.map((item, i) => {return <div key={i}><div className="point" /><div className="benefitBanner__item-text2">{item}</div></div>})}</div>
                            <div className="benefitBanner__item"><img src="/img/o!.svg" alt="o!.svg"/><div className="benefitBanner__item-text">{pageLangContent.benefit.oo.title}</div>{pageLangContent.benefit.oo.point.map((item, i) => {return <div key={i}><div className="point" /><div className="benefitBanner__item-text2">{item}</div></div>})}</div>
                        </div>
                        <div className="text">{pageLangContent.benefit.textAll[0]}</div>
                        <div className="gridText"><div className="gridText__item"><div className="point"/><div className="text">{pageLangContent.benefit.textAll[1]}</div></div><div className="gridText__item"><div className="point"/><div className="text">{pageLangContent.benefit.textAll[2]}</div></div></div>
                        <div className="title2">{pageLangContent.benefit.textPartner.text}</div>
                        <div>{pageLangContent.benefit.textPartner.point.map((item, i) => {return <div key={i} className="text">{item}</div>})}</div>
                        <div className="title2">{pageLangContent.benefit.textClient.text}</div>
                        <div>{pageLangContent.benefit.textClient.point.map((item, i) => {return <div key={i} className="text">{item}</div>})}</div>
                    </div>
                </div>

                <div id="marketing" className="content__item">
                    <div className="content__item-subItem" >
                        <div className="title">{pageLangContent.marketing.topBlock.title}</div>
                        <div className="text">{pageLangContent.marketing.topBlock.text}</div>
                        <div className="title2">{pageLangContent.marketing.middleBlock.title}</div>
                        <div>{pageLangContent.marketing.middleBlock.point.map((item, i) => {return <div key={i} className="text">{item}</div>})}</div>

                        <div className="marketingBanner">
                            <div className="marketingBanner__left">
                                <div className="marketingBanner__left-title">{pageLangContent.marketing.bannerOne.blockLeft.title}</div>
                                <div className="marketingBanner__left-text">{pageLangContent.marketing.bannerOne.blockLeft.text}</div>
                            </div>
                            <div className="marketingBanner__right">
                                <div className="marketingBanner__right-circleLeft textMedium"><div className="paddingSmall">{pageLangContent.marketing.bannerOne.blockRight.textMedium}</div></div>
                                <div className="marketingBanner__right-circleCenter"><div className="circleBigInner textBig"><div className="paddingSmall">{pageLangContent.marketing.bannerOne.blockRight.textBig}</div></div></div>
                                <div className="marketingBanner__right-circleRight textSmall"><div className="paddingSmall">{pageLangContent.marketing.bannerOne.blockRight.textSmall}</div></div>
                            </div>
                        </div>

                        <div className="marketingBanner">
                            <div className="marketingBanner__left">
                                <div className="marketingBanner__left-title">{pageLangContent.marketing.bannerTwo.blockLeft.title}</div>
                                <div className="marketingBanner__left-text">{pageLangContent.marketing.bannerTwo.blockLeft.text}</div>
                            </div>
                            <div className="marketingBanner__right">
                                <div className="marketingBanner__right-circleCenter"><div className="circleBigInner textBig">{pageLangContent.marketing.bannerTwo.blockRight.textBig}</div></div>
                                <div className="marketingBanner__right-circleRight textSmall"><div className="paddingSmall">{pageLangContent.marketing.bannerTwo.blockRight.textSmall}</div></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="partners" className="content__item content__item_backgroundGray"><div className="content__item-subItem" ><div className="title">{pageLangContent.partnersTitle}</div><div className="gridPartners">{partners}</div></div></div>

            </div>

            <div id="contacts" className="footer">
                <div className="text footer__items">
                    <div className="footer__items-item footer__link">
                        <div className="footer__title">{pageLangContent.contact.title}</div>
                        <div className="footer__description" dangerouslySetInnerHTML={{__html: pageLangContent.contact.text}}/>
                        <div className="footer__link"><a href={'mailto:' + pageLangContent.contact.textEmail}>Email: {pageLangContent.contact.textEmail.split('?')[0]}</a></div>
                    </div>
                    <div className="footer__items-item footer__contact">
                        <br />
                        <div>
                            <div className="footer__inn" dangerouslySetInnerHTML={{__html: pageLangContent.contact.description}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ru
