import React from "react"
import {Routes, Route} from "react-router-dom"
import ULanding from "./uLanding"
import "./App.css"

function Root() {
    return <Routes>
        <Route path="/*" element={<ULanding/>} />
        <Route path="/" element={<ULanding/>} />
    </Routes>
}

export default Root
